<template>
  <div>
    <van-nav-bar title="注册" left-text="返回" right-text="登录" left-arrow @click-left="back" @click-right="login" />
    <van-form class="form" @submit="submitFn">
      <van-field v-model="username" name="username" label="用户名" placeholder="请填写用户名" :rules="[{ required: true }]" />
      <van-field v-model="password" type="password" name="password" label="密码" placeholder="请填写密码" :rules="[{ required: true }]" />
      <div class="btn">
        <van-button class="reg" block round native-type="submit">注册</van-button>
        <van-button block round @click="back">返回</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { reg } from '@/api/user';
export default {
  name: 'Reg',
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    async submitFn(values) {
      try {
        const { code, msg } = (await reg(values)) || {};
        if (code === 200) {
          Notify({ type: 'success', message: msg });
          this.$router.push('/login');
        } else if (code === 400) {
          Notify({ type: 'danger', message: msg });
        } else {
          Notify({ type: 'danger', message: '注册失败' });
        }
      } catch (error) {
        Notify({ type: 'danger', message: '注册失败' });
      }
    },
    // 返回
    back() {
      this.$router.go(-1);
    },
    login() {
      this.$router.push({ name: 'login' });
    }
  }
};
</script>

<style scoped lang='scss'>
.form {
  padding: 10px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  .reg {
    margin-right: 10px;
    padding: 12px 6px;
    border: 1px solid #000;
    background-color: #000;
    border-radius: 22px;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    word-break: break-word;
  }
}
</style>
